/* 
 * Para organizar la caché y filtrado guardaremos en Vuex el estado actual de las vistas
 * Una vez filtrado un elemento, se llamará a addFilter ( $store.commit('addFilter') )
 * 
 * los campos a enviar son:
 * name: Nombre de la vista (ej: Empresas)
 * filter: Donde guardaremos en data el filtro en sí, la query, y la página actual donde nos encontramos
 * 
 * Estos campos serán consultados al cargar una vista, cambiar de página y borrar filtro.
 * Se puede comprobar su funcionamiento en /EmpresasFilter y /ListadoEmpresas
*/


export const cache = {
  state: {
    filters: {}
  },
  mutations: {
    addFilter(state, payload) {
      state.filters[payload.name] = payload.filter
    },
    removeFilter(state, payload) {
      delete state.filters[payload.name]
    },
    editFilter(state, payload) {
      state.filters[payload.name] = payload.filter
    }
  },
  actions: {
    addFilter(context, payload) {
      context.commit('addFilter', payload)
    },
    removeFilter(context, payload) {
      context.commit('removeFilter', payload)
    },
    editFilter(context, payload) {
      context.commit('editFilter', payload)
    }
  }
}