import { h, resolveComponent } from 'vue'

const PrincipalSaldo = () => import('@/app/views/publica/views/Principal');


const publicaRoutes = [
  {
    path: "/publica",
    name: "Principal",
    component: PrincipalSaldo,
    meta: {
      auth: false
    }
  },
];

export default publicaRoutes;
