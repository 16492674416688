export const BASE_URL = `${process.env.VUE_APP_API_DOMAIN}${process.env.VUE_APP_API_PATH}`; // domain/api
export const ADMIN_API = `${process.env.VUE_APP_API_DOMAIN}${process.env.VUE_APP_API_PATH}${process.env.VUE_APP_API_ADMIN}`; // domain/api/admin
export const CENTROS_API = `${process.env.VUE_APP_API_DOMAIN}${process.env.VUE_APP_API_PATH}${process.env.VUE_APP_API_CENTROS}`; // domain/api/centros

export const MEDICO_FIELDS = {
  name: '',
  email: '',
  password: '',
  repeatPassword: '',
  numero_colegiado: '',
  activo: true,
}

export const CENTRO_FIELDS = {
  nombre: '',
  persona_contacto: '',
  telefono: '',
  email: '',
  saldo: '',
}

export const PACIENTE_FIELDS = {
  centro_medico_id: '',
  nombre: '',
  apellidos: '',
  movil: '',
  sexo: '',
  nacimiento: '',
  codigo_postal: '',
  dni: '',
  email: ''
}

export const LESION_FIELDS = {
  titulo: '',
  categoria_id: '',
  coste: '',
  descripcion: '',
  centro_id: ''
}

export const EXPEDIENTE_FIELDS = {
  titulo: '',
  centro_id: '',
  paciente_id: '',
  medico_id: '',
  estado_id: 1,
  fecha: '',
  coste: 0,
  observaciones: '',
}


export const DIRECCIONES_FIELDS = {
  principal: false,
  alias: '',
  tipo_id: '',
  direccion: '',
  localidad: '',
  codigo_postal: '',
  provincia: '',
  pais: '',
  observaciones: '',
  nombre_fiscal: '',
  cif: '',
}

export const SET_PAGINATION = {
  paginaActual: 1,
  rows: 0,
  totalPaginas: 1,
  currentPage: 1,
  perPage: 20,
}

export const CALENDAR_LANG = {
  formatLocale: {
    firstDayOfWeek: 1,
    weekdaysMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
    monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    placeholder: {
      date: 'Selecciona una Fecha',
      dateRange: 'Selecciona un Rango de Fechas'
    }
  },
}

export const QUILL_CONFIG = {  // QUILL_CONFIG_SNOW
  theme: 'snow',
  placeholder: 'Escribe ...',
  modules: {
    toolbar: false,
     toolbar: [
       ["bold", "italic", "underline", "strike", "link"],
     ],
    syntax: {
      // eslint-disable-next-line no-undef
      highlight: text => hljs.highlightAuto(text).value
    }
  }
}


export const COMPANY_DATA = {
  company_name: `${process.env.VUE_APP_COMPANY_NAME}` ? `${process.env.VUE_APP_COMPANY_NAME}` : 'Talento-EPHOS',
  description: `${process.env.VUE_APP_COMPANY_DESCRIPTION}`,
  // email: `${process.env.VUE_APP_CLIENT_APP_MAIL}`,
  logo_path: 'logo/aesthemed-logo.png',
  logo_black_path: 'logo/logo-talento.png',
  logo_small_path: 'logo/aesthemed-small-logo.png',

  // ruta en front
  aviso_legal: './pdfs/Aesthemed_AEDERMA_AvisoLegal.pdf',
  cookies: './pdfs/Aesthemed_AEDERMA_PoliticaDeCookies.pdf',
  terminos_condiciones: './pdfs/Aesthemed_AEDERMA_PoliticaDePrivacidad.pdf',

  // nombre ficheros api
  condicion_general_contratacion: 'CondicionesGeneralesContratacion.pdf',
  consentimiento_explicito: 'ConsentimientoPacientes_Formulario.pdf',
  consentimiento_informado: 'Consentimiento_informado_Formulario.pdf',
  derecho_desistimiento: 'Desestimiento.pdf',
  manual_fotografia: 'ManualFotografia.pdf',
  // ficha_diagnostico: 'pdfs/BALDERMA_ficha_diagnostico_Balder.pdf' BALDERMA
};

export const CONFIG_PANEL = {
  responsive: true,
}

export const ESTADO_CREADO = 16;//creado
export const ESTADO_NOTIFICADO = 45;//notificado
export const ESTADO_ACEPTADO_CLIENTE = 21;//notificado
export const ESTADO_PENDIENTE_DIAGNOSTICO = 18;//Pendiente diagnóstico
export const ESTADO_PENDIENTE_DIAGNOSTICO_PARCIAL = 19;//Pendiente diagnóstico parcial
export const ESTADO_DIAGNOSTICADO = 20;//Pendiente diagnóstico parcial
export const ESTADO_CERRADO = 48;//Pendiente diagnóstico parcial

export const CENTROS_MODEL = { path: 'App\\Models\\Base\\Centros', model: 'centros'}
export const PACIENTES_MODEL = { path: 'App\\Models\\Base\\Pacientes', model: 'pacientes'}
export const MEDICOS_MODEL = { path: 'App\\Models\\Base\\Medicos', model: 'medicos'}
export const EXPEDIENTES_MODEL = { path: 'App\\Models\\Base\\Expedientes', model: 'expedientes'}


