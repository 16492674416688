<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler> 
      <CHeaderNav>
      <AppHeaderAccnt />
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
// import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import AppHeaderAccnt from './AppHeaderAccnt'
// import AppHeaderDropdownMssgs from './AppHeaderDropdownMssgs'
// import AppHeaderDropdownNotif from './AppHeaderDropdownNotif'
// import AppHeaderDropdownTasks from './AppHeaderDropdownTasks'


export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
    // AppHeaderDropdownAccnt,
    // AppHeaderDropdownMssgs,
    // AppHeaderDropdownNotif,
    // AppHeaderDropdownTasks,
    AppHeaderAccnt
  },
  setup() {
    return {
     
    }
  },
}
</script>
