<template>
  <div>
    <timeline class="">
      <timeline-item v-for="item in items" v-bind:key="item.id">
      <template #others>
        <CIcon size="lg" :name="`cil-check-circle`" :class="`text-success`"/>
      </template>
      <h4 class="mb-2">{{ item.titulo }}</h4>

      <div class="float-end">
        <small>{{ getFormatedDate(item.fecha) }}</small>
      </div>
      <div class="float-end me-2 text-primary">
          <strong><small class="mr-2">{{ item.logable_name }}</small></strong>
      </div>
    </timeline-item>
  </timeline>

  <h5 v-if="!items || items.length <= 0" class="text-center py-4">No hay datos disponibles <CIcon class="text-danger" name="cil-x-circle" size="xl" /></h5>
  </div>
</template>

<script>

export default {
  name: "CustomTimeLine",
  props: {
    logs: { type: Array, default() {return[]}, required: true},
    loading: Boolean,
    general: Boolean
  },
  computed: {
    items:{
      get: function () {
        return !this.general ? this.logs : this.logs.map(l => {
          l.notVisited = false;

          if(this.$store.state.fecha_notificaciones === null || moment(l.created_at) > moment(this.$store.state.fecha_notificaciones)) {
            l.notVisited = true;
          }

          return l;
        });
      },
      set: function (newValue) {
        this.$emit("update:logs", newValue);
      }
    },
    loadingList: {
      get: function() {
        return this.loading;
      },
      set: function(newValue) {
        this.$emit('update:loading', newValue)
      }
    },
  },
  methods: {
    getFormatedDate(date) {
      return moment(date).format('DD/MM/YYYY HH:mm');
    },

    getIcon(tipo) {
      return tipo == "Usuario" || tipo == "Paciente" ? "user" :
        tipo == "Pedido" ? "basket" :
        tipo == "Copiado" ? "copy" :
        tipo == "Activado" ? "check-circle" :
        tipo == "Comentario" ? "comment-bubble" :
        tipo == "Medico" ? "medical-cross" :
        tipo == "Empresa" ? "building" :
        tipo == "Eliminado" ? "x-circle" :
        tipo == "Documentacion" ? "file" :
        tipo == "Tarea" ? "task" : "";
    },

    getColor(tipo, titulo) {
      return tipo == "Usuario" || tipo == "Copiado" ? "info" :
        tipo == "Pedido" ? "dark" :
        tipo == "Activado" || tipo == "Comentario" || titulo.toLowerCase().includes("cread") ? "success" :
        tipo == "Eliminado" ? "danger" : "";
    },

    getTipo(tipo) {
      return tipo == "App\\Models\\Base\\Empresa" ? "Empresa" :
      tipo == "App\\Models\\Admin\\Administrador" ? "Administrador" : "";
    },

    getRuta(tipo) {
      return tipo == "App\\Models\\Base\\Empresa" ? "/gestion/empresas" :
      tipo == "App\\Models\\Admin\\Administrador" ? "/configuracion/usuarios" : "";
    }
  }
}
</script>

<style lang="scss" scoped>

h4 {
  font-size: 1rem;
  font-weight: bolder;
}

small {
  font-weight: lighter;
}

.visited {
  color: lightslategrey !important;
}
</style>
