<template>
  <div class="spinner" v-if="show" :class="{'full-screen' : fullScreen}">
    <div class="spinner-box">
      <div class="rect1"></div>
      <div class="rect2"></div>
      <div class="rect3"></div>
      <div class="rect4"></div>
      <div class="rect5"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpinnerTtt',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    fullScreen: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>

// .full-screen {
//   position: fixed;
// }

</style>
