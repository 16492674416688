export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-home',
  },
  {
    component: 'CNavItem',
    name: 'Expedientes',
    icon: 'cil-clipboard',
    to: '/expedientes',
    entidad: 'expedientes'
  },
  {
    component: 'CNavItem',
    name: 'Pacientes',
    icon: 'cil-people',
    to: '/pacientes',
    entidad: 'pacientes'
  },
  {
    component: 'CNavItem',
    name: 'Saldo',
    icon: 'cil-money',
    to: '/saldo',
    entidad: 'saldo'
  },
  {
    component: 'CNavItem',
    name: 'Documentación',
    icon: 'cil-book',
    to: '/manual',
    entidad: 'manual'
  },
  {
    component: 'CNavItem',
    name: 'Centro',
    icon: 'cil-user',
    to: '/centros/yo',
    entidad: 'centro'
  },
]
