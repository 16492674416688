<template>
  <div>
  </div>
</template>

<script>
import miniToastr from 'mini-toastr'// https://github.com/se-panfilov/mini-toastr

const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
}

miniToastr.init({types: toastTypes})

export default {
  name: 'toastr',
  props: ['title', 'message', 'type', 'timeout'],
  notifications: {
    show: {}
  },
  methods: {
    showToast(title, message, type, timeout) {
      miniToastr[type](message, title, timeout);
        //this.$notify({title: title, message: message, type: type, timeout: timeout})
    }
  }
}
</script>
