<template>
    <div class="animated fadeIn">
      <CModal v-model:visible="showModal" size="lg" @close="() => $emit('cancelled',false)">
        <spinner-ttt :show="!items"/>
        <CModalBody class="text-center d-block p-0 m-0">

            <CCarousel controls :wrap="false" :index="index">
                <CCarouselItem v-for="(item, i) in items">
                    <img class="d-block w-100" :src="item" :alt="`slide ${i + 1}`"/>
                </CCarouselItem>
            </CCarousel>
        </CModalBody>
      </CModal>
    </div>
  </template>
  
  <script>

  export default {
    name: 'VerImagenCarouselModal',
    props: {
      //alumno: { type: String, required: true },
      fotos: {type: Array, required: true},
      index: {type: Number, default: 0},
      show: { type: Boolean, default: false, required: true },
    },
    data() {
      return {
        loading: false
      }
    },
    computed: {
      showModal() {
        return this.show;
      },
      items: {
        get: function () {
          return this.fotos
        },
        set: function (newValue) {
          this.$emit("update:fotos", newValue);
        }
      },
      i: {
        get: function () {
          return this.index
        },
        set: function (newValue) {
          this.$emit("update:index", newValue);
        }
      },
    },
  }
  </script>

  <style lang="scss">

  .img-container {
    transition: all .3s ease-in-out;
  }
 </style>
  