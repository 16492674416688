import { createStore } from 'vuex'
import { coreUI } from './sidebar'
import { cache } from './filtros'
import { datos } from './datos'
export default createStore({
  modules: {
    coreUI,
    cache,
    datos,
  }
})
