<template>

  <transition name="fade">
    <!-- <CHeader class="mb-4 header-breardcrupm-custom"> -->
      <!-- <CContainer fluid> -->

       <div>
        <spinner-ttt :show="!migas"></spinner-ttt>
        <CBreadcrumb class="d-md-down-none me-auto" :style="{'margin-bottom': marginBottom}">
          <CBreadcrumbItem v-for="(miga, index) in migas" :key="index"
          :href="miga.href"> {{ checkBreadcrump(miga.label) }}</CBreadcrumbItem>

          <CBreadcrumbItem active>{{ activa }}</CBreadcrumbItem>
        </CBreadcrumb>
       </div> 

    <!-- </CContainer> -->
    <!-- </CHeader>  -->


  </transition>

</template>

<script>
export default {
  name: 'BreadcrumpsCustom',
  props: {
    migas:{ type: Array, required: true, default() { return []}},
    activa: { type: String, required: true},
    maginBottom: { type:String, default: '3rem'}
  },
  methods: {
    checkBreadcrump(miga) {
      return miga == undefined ? '...' : miga;
    }
  }
}
</script>

<style lang="scss" scoped>

.header-breardcrupm-custom {
  background-color: transparent;
  box-shadow: none;
  // box-shadow: 0 2px 2px 0 rgba(var(--cui-elevation-base-color, 60, 75, 100), .14), 0 3px 1px -2px rgba(var(--cui-elevation-base-color, 60, 75, 100), .12), 0 1px 5px 0 rgba(var(--cui-elevation-base-color, 60, 75, 100), .20);
}

.header.header-sticky {
  box-shadow:none !important;
}


</style>

