import { h, resolveComponent } from 'vue'

const DetalleCentro = () => import('@/app/views/centros/views/DetalleCentro');


const centrosRoutes = [
  {
    path: 'centros',
    name: 'Centros',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      }
    },
    children: [
      {
        path: 'yo',
        name: 'centro:Detalle',
        component: DetalleCentro,
        meta: {
          auth: true,
          entidad: 'centros'
        },
      }
    ]
  }
];

export default centrosRoutes;
