import { httpClient } from '@/app/shared/http-service';
import { apiGlobalConstants } from '../config';
import $store from '@/store';

const URL = apiGlobalConstants.tipologia

export const fetchTypologyWithSons = (slug) => {
  return httpClient.get(`${URL}/obtener/${slug}`).then(res => {
    // console.warn('Get ' + slug)
    $store.commit('addStore', {
      name: slug,
      tipo: 'tipologia',
      // id: res.data.item.id,
      data: {...res.data, tipo: 'tipologia'}
    });

    return  res.data
  });
}
