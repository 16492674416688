import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhone, faUser, faFlag } from "@fortawesome/free-solid-svg-icons";
import { faFileExcel } from "@fortawesome/free-regular-svg-icons";

library.add(faPhone, faUser, faFlag, faFileExcel);

/* <font-awesome-icon :icon="['far', 'file-excel']" /> */
/* <font-awesome-icon icon="fa-regular fa-file-excel" /> */

export default FontAwesomeIcon;