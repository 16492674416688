<template>

  <CCard class="mb-2">
    <CCardHeader class="pointer" @click="timelineCollapsed = !timelineCollapsed">
    <CIcon name="cil-graph" size="lg" class="me-2" />
    <slot name="header"><strong>Timeline</strong></slot>
    <div class="card-header-actions float-end">
      <CIcon v-if="timelineCollapsed" name="cil-chevron-bottom" />
      <CIcon v-else name="cil-chevron-top" />
    </div>
  </CCardHeader>
  <spinner-ttt :show="loadingLogs"></spinner-ttt>
  <CCollapse :visible="!timelineCollapsed" :duration="400">
    <CCardBody style="max-height:500px" class="timeline-card overflow-auto">
      <CustomTimeLine v-model:logs="items" v-model:loading="loadingLogs" />
    </CCardBody>
  </CCollapse>

  </CCard>
  <toast ref="toast"></toast>
</template>

<script>


export default {
  name: 'CardListadoLogs',
  props: {
    loading: { type: Boolean, default: true},
    logs: {type: Array, default() {return []}, required: true },
  },
  data() {
    return {
      timelineCollapsed: true,
    }
  },
  emits: ['update:logs', 'update:loading'],
  computed: {
    items: {
      get: function () {
        return this.logs
      },
      set: function (newValue) {
        this.$emit('update:logs', newValue)
      },
    },
    loadingLogs: {
      get: function () {
        return this.loading
      },
      set: function (newValue) {
        this.$emit('update:loading', newValue)
      },
    },
  },

  methods: {

  },
}
</script>

<style lang="scss" scoped>


.card-footer {

  &.white{
    background-color: #ffffff;
    border-top: 0.5px solid var(--cui-border-color-translucent);
  }

  &.new-comment {
    background-color: #ffffff;
    border-top: 2px solid var(--cui-border-color-translucent);
    padding-top: 2rem;
  }

  // padding: var(--cui-card-cap-padding-y) var(--cui-card-cap-padding-x);
  // color: var(--cui-card-cap-color);
  // background-color: var(--cui-card-cap-bg);
  // border-top: var(--cui-card-border-width) solid var(--cui-card-border-color);


}
</style>
