import { h, resolveComponent } from 'vue'

const DetalleManual = () => import('@/app/views/manual/views/DetalleManual');



const manualRoutes = [
  {
    path: 'manual',
    name: 'Manual',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      }
    },
    children: [
      {
        path: '',
        name: 'manual:Detalle',
        component: DetalleManual,
        meta: {
          auth: true,
          entidad: 'manual'
        },
      }
    ]
  }
];

export default manualRoutes;
