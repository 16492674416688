<template>
  <div class="animated fadeIn">
    <CModal v-model:visible="showModal" size="lg" @close="() => $emit('cancelled',false)">

      <CModalHeader class="bg-danger text-light">
        <CModalTitle>{{ title }}</CModalTitle>
      </CModalHeader>

      <CModalBody class="text-center d-block">
        <h3>Eliminar {{ this.title.toLowerCase() }} </h3>
        <p>¿Quieres eliminar definitivamente {{ this.element }}?</p>
        <slot></slot>
      </CModalBody>

       <CModalFooter>
        <CButton @click="$emit('cancelled',false)" color="outline-warning">Cancelar</CButton>
        <CButton class="text-light" @click="$emit('deleteItems',false)" color="danger">
          <!-- <CIcon name="cil-check-circle" />  -->
          Eliminar
        </CButton>
       </CModalFooter>

    </CModal>
  </div>
</template>

<script>

export default {
  name: 'DeleteModal',
  props: {
    title: { type: String, default: 'Eliminar', required: true },
    show: { type: Boolean, default: false, required: true },
    element: { type: String, default: 'Elemento', required: true},
  },
  computed: {
    showModal() {
      return this.show;
    },
  },
}
</script>
